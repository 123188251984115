// import node_modules
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import React from 'react';

// import React components
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout
    canonical="https://echogramm.de/404.html"
    description="Ooops. Die Seite existiert leider nicht. Gehe zurück zur Startseite."
    image="https://echogramm.de/img/echogramm-bubbles.jpg"
    lang="de"
    title="Echogramm | Seite nicht gefunden"
  >
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <img
      src="/img/echogramm-bubbles.jpg"
      alt="Echogramm - Frischer Wind in Deiner Filterblase"
      title="Echogramm - Frischer Wind in Deiner Filterblase"
      width="100%"
      height="auto"
      style={{ marginTop: '-500px' }}
    />
    <section id="not-found" className="section bg-white">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-three-quarters-tablet is-two-thirds-desktop is-three-fifths-widescreen is-half-fullhd has-text-centered">
            <h1 className="title is-size-2">Ooops. Seite nicht gefunden.</h1>
            <p>
              <Link to="/" className="button is-echogramm">
                Zurück zur Startseite
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="demo" className="section bg-demo">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <h2 className="is-size-2">Demo anfordern</h2>
            <p className="is-size-5">
              Das Echogramm ist eine Social News App, die dir alle wichtigsten News deiner Branche
              auf einen Blick liefert. In einem persönlichen Gespräch zeigen wir dir die Funktionen
              und Möglichkeiten des Echogramms für dein Unternehmen. Du möchtest frischen Wind in
              deiner und der Filterblase deines Teams? Fordere jetzt unverbindlich eine persönliche
              Produktvorführung an.
            </p>
            <p>
              <a
                href="mailto:echogramm@mii.ventures?subject=Produktdemo Echogramm"
                className="button is-echogramm"
              >
                Produktdemo anfordern
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
